import {
  getChildrenFromList,
  getDateTimeRange,
  getEnterpriseCustomTimeSpan,
  getThingsIdMapByArray
} from '@/tools/functions';
import { Chart, ChartType, Dataset, Options, TimeRanges } from '@colven/common-domain-lib/lib';
import moment from 'moment';
import harvestTargetApi from "../api/harvestTarget.api";
import thingApi from '../api/thing.api';
import {
  CHART_HARVEST_PROGRESS,
  FILTER_STATES
} from "@/components/objective-tendency/components/HarvestProgressComponent/harvestProgressConstants";
import enterpriseService from './enteprise.service';
import { mapCurrentStateService } from './mapCurrentStateService';
import objectiveTendencyConstants from '@/constants/objectiveTendency.constants'
import i18n from '@/i18n'

class ObjectiveTendency {
  async getObjectiveTendencyByIdThings(things, from, to) {
    return await harvestTargetApi.getHarvestProgress({things: things, from, to});
  }

  async getTendencyStatusByIdThings(things, from, to, reportByHours) {
    things.forEach(thing => {
      thing.from = from;
      thing.to = to;
    });

    if (reportByHours ) {
      const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'));
      return await harvestTargetApi.getTendencyStatusByHour(things, enterpriseId);
    }
    return await harvestTargetApi.getTendencyStatus(things)
  }

  async getTendencyStatus(data) {
    const [reportType, activityFilter, thingTypeFilter, thingsFilter, stateFilter] = data.filters;
    thingTypeFilter;
    const reportByWorkFront = reportType.selectedData[0].id === "WORK_FRONT";
    const activityId = activityFilter.selectedData[0].id;
    const state = stateFilter.selectedData[0];
    const conversion = (data.selectedDateAndTimeRangeCustomType !== 'DAY') ? this.getDateWithEnterpriseTimeSpan(data) : {tFrom: new Date(data.sinceDate).getTime() / 1000};
    if (data.selectedDateAndTimeRange === TimeRanges.LAST_WEEK) {
      const enterpriseTimeSpanUTC = enterpriseService.getEnterpriseTimeSpanUTC();
      conversion.tFrom = moment.unix(conversion.tFrom).utcOffset(0).set('hour', enterpriseTimeSpanUTC).set('minutes', 0).set('seconds', 0).unix();
    }
    const things = [];
    const selectedThing = reportByWorkFront ? this.getThingsByHarvestFrontSelected(thingsFilter.selectedData, thingsFilter.dataTemp) : thingsFilter.selectedData;
    const {thingsWorkFrontMap, workFrontMap} = this.getThingsWorkFrontMapAndWorkFrontMap(selectedThing, data.colorScheme);
    getChildrenFromList(selectedThing, things);

    let timeRangeType = data.selectedDateAndTimeRangeCustomType;
    if (data.selectedDateAndTimeRangeCustomType !== 'DAY' && (data.selectedDateAndTimeRange === 'today' || data.selectedDateAndTimeRange === 'enterprise')) {
      timeRangeType = data.selectedDateAndTimeRange;
    }
    const reportByHours = (timeRangeType === 'date' && ((conversion.tTo - conversion.tFrom) / 3600) <= 24) || timeRangeType === 'DAY' || timeRangeType === 'enterprise';
    const workedByDayData = await this.getTendencyStatusByIdThings(things, conversion.tFrom, conversion.tTo, reportByHours);
    const timezone = workedByDayData.timezone;
    const result = workedByDayData.result || workedByDayData;
    const dataResult = reportByWorkFront ? this.getDataGroupedByWorkFront(result, thingsWorkFrontMap, workFrontMap) : result;
    if (!reportByWorkFront) {
      dataResult.forEach(thing => {
        thing.color = workFrontMap[thingsWorkFrontMap[thing.id].workFrontId].color;
      });
    }
    const chartData = this.getTendencyChartData(dataResult, activityId, state.key, reportByHours, timezone);
    const date = this.getDateTimeRangeForTitle(data, conversion);
    const chart = this.getLineChart(chartData, date);
    return [chart];
  }

  getDataGroupedByWorkFront(workedByDayData, thingsWorkFrontMap, workFrontMap) {
    const result = [];
    const thingIds = Object.keys(thingsWorkFrontMap);
    thingIds.forEach(thingId => {
      const workFrontId = thingsWorkFrontMap[thingId].workFrontId
      const workFrontName = workFrontMap[workFrontId].name;
      const workFrontData = result.find(workFront => workFront.name === workFrontName);

      if (!workFrontData) {
        result.push({
          name: workFrontName,
          color: workFrontMap[workFrontId].color,
          statusByDate: workedByDayData.find(thing => thing.id === thingId).statusByDate
        });
      } else {
        const thingData = workedByDayData.find(thing => thing.id === thingId);
        const dates = Object.keys(thingData.statusByDate);
        dates.forEach(date => {
          const activities = Object.keys(thingData.statusByDate[date]);
          activities.forEach(activityId => {
            const states = Object.keys(thingData.statusByDate[date][activityId]);
            states.forEach(stateKey => {
              workFrontData.statusByDate[date] = workFrontData.statusByDate[date] || {};
              workFrontData.statusByDate[date][activityId] = workFrontData.statusByDate[date][activityId] || {};
              workFrontData.statusByDate[date][activityId][stateKey] = workFrontData.statusByDate[date][activityId][stateKey] || 0;
              workFrontData.statusByDate[date][activityId][stateKey] += thingData.statusByDate[date][activityId][stateKey];
            })
          })
        });
      }
    });
    return result;
  }

  getTendencyChartData(data, activityId, stateKey, reportByHours, timezone) {
    let labels = Object.keys(data[0].statusByDate).sort();

    const datasets = data.map(thing => {
      const chartData = labels.map((label) => {
        if (!thing.statusByDate[label][activityId] || !thing.statusByDate[label][activityId][stateKey]) {
          return 0;
        }
        return +(Number(thing.statusByDate[label][activityId][stateKey] / 3600).toFixed(2)) || 0;
      })

      return {
        label: thing.name,
        color: thing.color,
        data: chartData,
      };
    })

    // convierte ms a YYYY-MM-DD
    labels = labels.map((label) => {
      const formattedDate = new Date(Number(label)).toISOString().split('T');
      return reportByHours ? this.getFormattedDateWithTimezone(formattedDate[1].substring(0, 5), timezone) : formattedDate[0];
    });

    return {
      name: "Total",
      labels,
      datasets
    };
  }

  getFormattedDateWithTimezone(substring, timezone) {
    let date = substring;
    if (timezone) {
      const hour = Number(substring.substring(0, 2)) + timezone;
      date = this.getCompleteDateHour((hour < 0) ? (24 + hour) : hour) + ':00';
    }
    return date;
  }

  getCompleteDateHour(dateHour) {
    return (dateHour + '').length > 1 ? dateHour : '0' + dateHour;
  }

  getLineChart(chartData, date) {
    const chart = new Chart();
    // id
    chart.id = 'tendency-chart-line';
    // nombre
    chart.name = chartData.name;
    // tipo
    chart.type = ChartType.LINE;
    // labels
    chart.data.labels = chartData.labels;

    // Dataset
    chartData.datasets.forEach(datasetData => {
      const dataset = new Dataset();
      dataset.data = datasetData.data;
      dataset.label = datasetData.label;
      // Color
      const selectedColor = datasetData.color ? datasetData.color.hex : '';
      const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
      dataset.borderColor = selectedColor || randomColor;
      dataset.backgroundColor = selectedColor || randomColor;

      // Agrego el dataset creado
      chart.data.datasets.push(dataset);
    });

    // Options
    const options = new Options();
    options.tooltips.mode = 'point';
    options.title.text = date;
    chart.options = options;

    chart.showDatalabels = false;
    return chart;
  }

  async getHarvestProgressChart(data) {
    const workShiftCount = (localStorage.getItem('enterpriseTimeSpan') && parseInt(JSON.parse(localStorage.getItem('enterpriseTimeSpan')).shift)) || 4;
    const [reportType, activityFilter, thingTypeFilter, thingsFilter, stateFilter] = data.filters;
    thingTypeFilter;
    const reportByWorkFront = reportType.selectedData[0].id === "WORK_FRONT";
    const activityId = activityFilter.selectedData[0].id;
    const state = stateFilter.selectedData[0];
    const conversion = this.getDateWithEnterpriseTimeSpan(data);
    const things = [];
    const selectedThing = reportByWorkFront ? this.getThingsByHarvestFrontSelected(thingsFilter.selectedData, thingsFilter.dataTemp) : thingsFilter.selectedData;
    const {thingsWorkFrontMap, workFrontMap} = this.getThingsWorkFrontMapAndWorkFrontMap(selectedThing);
    this.getChildrenThingsData(selectedThing, things);
    let thingsIds = things.map(item => item.id);
    let thingsState = await mapCurrentStateService.getThingStateByIdThings(thingsIds, {trackTimestamp: 1, idThing: 1})
    this.addConnectionState(things, thingsState);
    const stateObjects = await thingApi.getStateTargetsByThingIds(things.map((thing) => thing.id));
    const thingsWithoutStateTargets = things.filter((thing) => !stateObjects[thing.id]);
    const workShiftData = await this.getObjectiveTendencyByIdThings(things, conversion.tFrom, conversion.tTo);
    const charts = this.initCharts(workShiftCount, state.name, things);
    charts.forEach(chart => chart.options.title.text = this.getDateTimeRangeForTitle(data));
    const thingsMap = getThingsIdMapByArray(things);

    await this.generateChartsByWorkShift(
      workShiftData, charts, workShiftCount, activityId, state, stateObjects, thingsMap, reportByWorkFront,
      thingsWorkFrontMap, workFrontMap, data.percentageResult
    );
    this.categorizeThingsByConnectionState(things, charts, reportType.selectedData[0].id, selectedThing, thingsMap);
    return {charts, thingsWithoutStateTargets};
  }

  categorizeThingsByConnectionState(things, charts, reportType, selectedThings, thingsMap) {
    let colors = []
    if (reportType === "WORK_FRONT") {
      selectedThings.forEach(thing => {
        let children = thing.children;
        let index = 0;
        let stop = false;
        let currentConnectionState = thingsMap[children[0].id].connectionState;
        while (index < children.length && !stop) {
          switch (currentConnectionState.state) {
            case objectiveTendencyConstants.harvestConectionState.high:
              stop = true;
              break;
            case objectiveTendencyConstants.harvestConectionState.medium:
              currentConnectionState = thingsMap[children[index].id].connectionState;
              index++;
              break;
            case objectiveTendencyConstants.harvestConectionState.connected:
              index++;
              break;
            default:
              index++;
              break;
          }
        }
        colors.push(currentConnectionState.color);
      });

    } else {
      things.map(item => colors.push(item.connectionState.color));
    }
    charts.forEach(item => {
      item.data.datasets[0].backgroundColor = colors;
      item.data.datasets[0].borderColor = colors;
    })
  }

  addConnectionState(things, thingsState) {
    let thingsStateMap = {};
    if (thingsState && thingsState.length) {
      for (const thing of thingsState) {
        thingsStateMap[thing.idThing] = thing;
      }
    }

    things.forEach(item => {
      if (thingsStateMap[item.id]) {
        item.connectionState = this.getConnexionState(thingsStateMap[item.id].trackTimestamp);
      } else {
        item.connectionState = {color: "#0F952A", state: objectiveTendencyConstants.harvestConectionState.connected};
      }
    });
  }

  getChildrenThingsData(selectedThings, result) {
    selectedThings.forEach(item => {
      if (item.children) {
        this.getChildrenThingsData(item.children, result);
      } else {
        result.push(item);
      }
    });
  }

  getConnexionState(trackTimeStamp) {
    const today = new Date().getTime() / 1000;

    if ((today - 600) >= trackTimeStamp && (today - 3600) < trackTimeStamp) {
      return {color: "#FFEB3B", state: objectiveTendencyConstants.harvestConectionState.medium};
    }
    if ((today - 3600) > trackTimeStamp) {
      return {color: "#F44336", state: objectiveTendencyConstants.harvestConectionState.high};
    }
    return {color: "#0F952A", state: objectiveTendencyConstants.harvestConectionState.connected};
  }

  getDateTimeRangeForTitle(data, conversion) {
    let titleText;
    let tFrom;
    let tTo;
    let date;
    const timezone = Number(localStorage.getItem('timezone'));
    switch (data.selectedDateAndTimeRange) {
      case 'custom':
        if (data.selectedDateAndTimeRangeCustomType === 'DAY') {
          titleText = this.formatDate(data.sinceDate);
        } else if (!data.toTime || !data.sinceTime) {
          titleText = this.formatDate(data.sinceDate) + " - " + this.formatDate(data.toDate);
        } else {
          titleText = this.formatDate(data.sinceDate) + " " + data.sinceTime + " - " + this.formatDate(data.toDate) + " " + data.toTime;
        }
        break;
      case 'enterprise':
        tFrom = this.formatDate(getEnterpriseCustomTimeSpan(), true);
        tTo = this.formatDate(moment().unix(), true);
        titleText = tFrom + " - " + tTo;
        break;
      case 'enterprisePrevious':
        tTo = this.formatDate(getEnterpriseCustomTimeSpan(), true);
        // El inicio de la jornada anterior es el mismo inicio que el de la jornada diaria restándole 24 hs
        console.log(moment.unix(getEnterpriseCustomTimeSpan()).subtract(1, 'days').unix());
        tFrom = this.formatDate(moment.unix(getEnterpriseCustomTimeSpan()).subtract(1, 'days').unix(), true);
        titleText = tFrom + " - " + tTo;
        break;
      case 'today':
        tFrom = getDateTimeRange(data.selectedDateAndTimeRange, data.selectedDateAndTimeRangeCustomType,
          data.sinceDate, data.sinceTime, data.toDate, data.toTime).tFrom;
        titleText = moment.unix(tFrom).format('DD/MM/YYYY');
        break;
      case 'lastWeek':
        date = !conversion ? getDateTimeRange(data.selectedDateAndTimeRange, data.selectedDateAndTimeRangeCustomType,
          data.sinceDate, data.sinceTime, data.toDate, data.toTime) : {tFrom: conversion.tFrom, tTo: conversion.tTo};
        tFrom = date.tFrom;
        tTo = date.tTo;
        titleText = moment.unix(tFrom).utcOffset(timezone).format('DD/MM/YYYY HH:mm:ss') + ' - ' + moment.unix(tTo).utcOffset(timezone).format('DD/MM/YYYY HH:mm:ss');
        break;
    }
    return titleText;
  }

  formatDate(date, isUnix = false) {
    if (isUnix) {
      const momentUnix = moment.unix(date);
      return momentUnix.format('DD/MM/YYYY HH:mm:ss')

    }
    const dateArray = date.split('-');
    return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0]
  }


  getDateWithEnterpriseTimeSpan(data) {
    if (data.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
      const enterpriseTimeSpanUTC = enterpriseService.getEnterpriseTimeSpanUTC();
      const tTo = moment(data.toDate, 'YYYY-MM-DD').utc().set('hour', enterpriseTimeSpanUTC).unix();
      const tFrom = moment(data.sinceDate, 'YYYY-MM-DD').utc().set('hour', enterpriseTimeSpanUTC).unix();
      return {
        tTo,
        tFrom
      };
    }
    return getDateTimeRange(data.selectedDateAndTimeRange, data.selectedDateAndTimeRangeCustomType,
      data.sinceDate, data.sinceTime, data.toDate, data.toTime);
  }

  getThingsByHarvestFrontSelected(selected, thingByWorkFront) {
    const result = [];
    if (selected && selected.length && thingByWorkFront && thingByWorkFront.length) {
      for (const workFrontSelected of selected) {
        const filterResult = thingByWorkFront.find((workFront) => workFront.id === workFrontSelected.id);
        if (filterResult) {
          result.push(filterResult);
        }
      }
    }
    return result;
  }

  initCharts(workShiftCount, stateName) {

    const result = [];
    const chartConfig = JSON.parse(JSON.stringify(CHART_HARVEST_PROGRESS));
    chartConfig.name = i18n.t('objectiveTendency.total');
    chartConfig.id = "harvestProgressChart0";
    chartConfig.data.datasets[0].label = stateName;
    chartConfig.options.title.text = "desde hasta*";
    result.push(chartConfig);
    for (let i = 0; i < workShiftCount; i++) {
      const chartConfig = JSON.parse(JSON.stringify(CHART_HARVEST_PROGRESS));
      chartConfig.name = i18n.t('objectiveTendency.shift') + " " + (i + 1);
      chartConfig.options.title.text = "desde hasta*";
      chartConfig.id = "harvestProgressChart" + i + 1;
      chartConfig.data.datasets[0].label = stateName;
      result.push(chartConfig);
    }
    return result;
  }


  getPercentage(value, maxValue) {
    if (maxValue) {
      return ((value * 100) / maxValue).toFixed(2);
    }
    return 0;
  }

  async generateChartsByWorkShift(
    workShiftsData, chartsConfig, workShiftCount, activityId, state, stateObjetives, thingsMap, reportByWorkFront,
    thingsWorkFrontMap, workFrontMap, isPercentage
  ) {
    if (reportByWorkFront) {
      const workFrontData = {};
      const objetiveByWorkFront = {};
      for (const workShift of workShiftsData) {
        const currentWorkFrontId = thingsWorkFrontMap[workShift.thingId].workFrontId;
        if (!workFrontData[currentWorkFrontId]) {
          workFrontData[currentWorkFrontId] = {total: workShift.total, workShifts: workShift.workShifts};
          objetiveByWorkFront[currentWorkFrontId] = parseFloat(((stateObjetives[workShift.thingId] && stateObjetives[workShift.thingId].stateTargets[state.id]) || 0));
        } else {
          const currentWorkFront = workFrontData[currentWorkFrontId];
          const currentObjective = ((stateObjetives[workShift.thingId] && stateObjetives[workShift.thingId].stateTargets[state.id]) || 0);
          objetiveByWorkFront[currentWorkFrontId] += parseFloat(currentObjective);
          for (const state of FILTER_STATES) {
            currentWorkFront.total[activityId] = currentWorkFront.total[activityId] ? currentWorkFront.total[activityId] : {};
            currentWorkFront.total[activityId][state] = ((currentWorkFront.total[activityId] && currentWorkFront.total[activityId][state] || 0) + (workShift.total[activityId] && workShift.total[activityId][state] || 0));
            for (let i = 0; i < workShiftCount; i++) {
              currentWorkFront.workShifts[i][activityId] = currentWorkFront.workShifts[i][activityId] ? currentWorkFront.workShifts[i][activityId] : {};
              currentWorkFront.workShifts[i][activityId][state] = ((currentWorkFront.workShifts[i][activityId] && currentWorkFront.workShifts[i][activityId][state] || 0) + (workShift.workShifts[i][activityId] && workShift.workShifts[i][activityId][state] || 0));
            }
          }
        }
      }
      for (const workFrontIds of Object.keys(workFrontData)) {
        const workFrontName = workFrontMap[workFrontIds].name;
        const totalTab = chartsConfig[0];
        const currentTotalValue = this.getStatesValuesByActivityAndStates(workFrontData[workFrontIds].total, activityId, state.key);
        const totalExpected = objetiveByWorkFront[workFrontIds];
        totalTab.data.datasets[0].data.push(isPercentage ? this.getPercentage(currentTotalValue, totalExpected) : currentTotalValue);
        totalTab.data.datasets[1].data.push(isPercentage ? 100 : totalExpected);
        totalTab.data.labels.push(workFrontName);
        for (let i = 0; i < workShiftCount; i++) {
          const currentWorkShiftTab = chartsConfig[i + 1];
          const currentValue = this.getStatesValuesByActivityAndStates(workFrontData[workFrontIds].workShifts[i], activityId, state.key);
          const expected = parseFloat((objetiveByWorkFront[workFrontIds] / workShiftCount).toFixed(2));
          currentWorkShiftTab.data.datasets[0].data.push(isPercentage ? this.getPercentage(currentValue, expected) : currentValue);
          currentWorkShiftTab.data.datasets[1].data.push(isPercentage ? 100 : expected);
          currentWorkShiftTab.data.labels.push(workFrontName);
        }
      }
    } else {
      //Cuando se filtra por vehículo
      for (const workShift of workShiftsData) {
        const thingName = thingsMap[workShift.thingId].name;
        const totalTab = chartsConfig[0];
        const stateTargetValue = stateObjetives[workShift.thingId] && stateObjetives[workShift.thingId].stateTargets[state.id] || 0;
        const currentTotalValue = this.getStatesValuesByActivityAndStates(workShift.total, activityId, state.key);
        const totalExpected = stateTargetValue;
        totalTab.data.datasets[0].data.push(isPercentage ? this.getPercentage(currentTotalValue, totalExpected) : currentTotalValue);
        totalTab.data.datasets[1].data.push(isPercentage ? 100 : totalExpected);
        totalTab.data.labels.push(thingName);
        for (let i = 0; i < workShiftCount; i++) {
          const currentWorkShiftTab = chartsConfig[i + 1];
          const currentValue = this.getStatesValuesByActivityAndStates(workShift.workShifts[i], activityId, state.key);
          const expected = parseFloat((stateTargetValue / workShiftCount).toFixed(2));
          currentWorkShiftTab.data.datasets[0].data.push(isPercentage ? this.getPercentage(currentValue, expected) : currentValue);
          currentWorkShiftTab.data.datasets[1].data.push(isPercentage ? 100 : expected);
          currentWorkShiftTab.data.labels.push(thingName);
        }
      }
    }
  }

  getStatesValuesByActivityAndStates(workShiftData, activityId, stateKey) {
    if (workShiftData[activityId] && workShiftData[activityId][stateKey]) {
      const seconds = workShiftData[activityId][stateKey];
      const result = parseFloat((seconds / 60) / 60);
      return result.toFixed(2);
    }
    return 0;
  }

  getWorkFrontToSelect(workFronts) {
    const result = [];
    if (workFronts && workFronts.length) {
      for (const workFront of workFronts) {
        if (workFront.children && !workFront.typeKey) {
          result.push({id: workFront.id, name: workFront.name});
        }
      }
    }

    return result;
  }

  getThingsWorkFrontMapAndWorkFrontMap(workFrontData, colorScheme = {}) {
    const result = {thingsWorkFrontMap: {}, workFrontMap: {}};
    if (workFrontData && workFrontData.length) {
      for (const workFront of workFrontData) {
        if (workFront.children && workFront.children.length) {
          result.workFrontMap[workFront.id] = {name: workFront.name, id: workFront.id, color: colorScheme[workFront.id]};
          for (const thing of workFront.children) {
            result.thingsWorkFrontMap[thing.id] = {name: thing.name, id: thing.id, workFrontId: workFront.id};
          }
        } else {
          result.thingsWorkFrontMap[workFront.id] = {name: workFront.name, id: workFront.id, workFrontId: null, color: colorScheme[workFront.id]};
        }
      }
    }
    return result;
  }
}

export default new ObjectiveTendency();
