import OfflineHarvestTableComponent from '@/components/objective-tendency/components/OfflineHarvestTableComponent/OfflineHarvestTableComponent.vue'
import LinkedTrucksTableComponent from '@/components/objective-tendency/components/LinkedTrucksTableComponent/LinkedTrucksTableComponent.vue'
import ObjectivesPerStatesComponent from '@/components/objective-tendency/components/ObjectivesPerStatesComponent/ObjectivesPerStatesComponent.vue'
import HarvestProgressComponent from '@/components/objective-tendency/components/HarvestProgressComponent/HarvestProgressComponent.vue'
import TendencyPanelComponent from '@/components/objective-tendency/components/TendencyPanelComponent/TendencyPanelComponent.vue'
import HarvestFrontTargetComponent from '@/components/objective-tendency/components/HarvestFrontTargetComponent/HarvestFrontTargetComponent.vue'

export default {
  name: 'ObjectiveTendencyComponent',
  components: {
    OfflineHarvestTableComponent,
    LinkedTrucksTableComponent,
    ObjectivesPerStatesComponent,
    HarvestProgressComponent,
    TendencyPanelComponent,
    HarvestFrontTargetComponent
  },
  props: {
  },
  data: () => ({
    tab: null,
  }),
  created () {
  },
  async mounted () {
  },
  computed: {},
  methods: {
  }
}
