import { render, staticRenderFns } from "./ObjectivesPerStatesComponent.vue?vue&type=template&id=37f65247&scoped=true"
import script from "./ObjectivesPerStatesComponent.js?vue&type=script&lang=js&external"
export * from "./ObjectivesPerStatesComponent.js?vue&type=script&lang=js&external"
import style0 from "./ObjectivesPerStatesComponent.vue?vue&type=style&index=0&id=37f65247&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f65247",
  null
  
)

export default component.exports