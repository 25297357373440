import { render, staticRenderFns } from "./LinkedTrucksTableComponent.vue?vue&type=template&id=51dce38e&scoped=true"
import script from "./LinkedTrucksTableComponent.js?vue&type=script&lang=js&external"
export * from "./LinkedTrucksTableComponent.js?vue&type=script&lang=js&external"
import style0 from "./LinkedTrucksTableComponent.vue?vue&type=style&index=0&id=51dce38e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51dce38e",
  null
  
)

export default component.exports