import businessTargetApi from "../api/businessTarget.api";
import stateApi from "../api/state.api";
import {objectiveTendencyStatesKeys} from '../constants/constants'

   const getWorkFrontTargetData =  async(thingsIds)=>{
        let thingsStateTargets = await businessTargetApi.getStateTargetsByThingsId(thingsIds)
        let stateTargetsTypes = await stateApi.getStatesTypeByKey(objectiveTendencyStatesKeys)
        const lang = localStorage.getItem('locale') || 'es'
       
        //Inicializo stateTargetsMapResult
        let tableData = []
        let stateTargetsMapResult ={}
        objectiveTendencyStatesKeys.forEach(key=>{
            stateTargetsMapResult[key]={
                value: 0,
                name: stateTargetsTypes[key].name[lang],
                id: stateTargetsTypes[key]._id
            }
        })

        //Cargo stateTargetsMapResult
       let totalTarget = 0
        thingsIds.forEach(id=>{
            if(thingsStateTargets[id] && thingsStateTargets[id].stateTargets){
                objectiveTendencyStatesKeys.forEach(key=>{
                    let typeId = stateTargetsTypes[key]._id
                    if(thingsStateTargets[id].stateTargets && thingsStateTargets[id].stateTargets[typeId]){
                        stateTargetsMapResult[key].value += parseFloat(thingsStateTargets[id].stateTargets[typeId])
                        stateTargetsMapResult[key].thingCountByTargetsAndKey = stateTargetsMapResult[key].thingCountByTargetsAndKey? stateTargetsMapResult[key].thingCountByTargetsAndKey + 1 : 1
                        totalTarget += parseFloat(thingsStateTargets[id].stateTargets[typeId])
                    }
                })
            }
        })
       
        //Armo tableData
        objectiveTendencyStatesKeys.forEach(key=>{
            tableData.push({
                key: key,
                stateTargetName: stateTargetsMapResult[key].name,
                workFrontValue: stateTargetsMapResult[key].value.toFixed(2),
                thingValue: (stateTargetsMapResult[key].value / (stateTargetsMapResult[key].thingCountByTargetsAndKey)).toFixed(2),           
                percentage: ((stateTargetsMapResult[key].value * 100)/ totalTarget).toFixed(2)

            })
        })

        return tableData;

    }



export default {getWorkFrontTargetData};