import i18n from '@/i18n';

export const CHART_HARVEST_PROGRESS = {
    "id": "",
    "name": "",
    "type": "bar",
    "data": {
        "labels": [
        ],
        "datasets": [
            {
                "type": "bar",
                "label": "",
                "data": [
                ],
                "formattedTooltipData": {
                    "label": [
                    ]
                },
                "formattedLabelData": [
                ],
                "backgroundColor": "#0F952A",
                "borderColor": "#0F952A",
                "borderWidth": 1,
                "fill": false,
                "lineTension": 0
            },
            {
                "type": "line",
                "label": i18n.t("objectiveTendency.harvestProgress.goal"),
                "data": [
                ],
                "formattedTooltipData": {
                    "label": [
                    ]
                },
                "formattedLabelData": [
                ],
                "backgroundColor": "#0089FF",
                "borderColor": "#0089FF",
                "borderWidth": 1,
                "fill": false,
                "lineTension": 0
            }
        ]
    },
    "options": {
        "responsive": true,
        "maintainAspectRatio": false,
        "legend": {
            "position": "top"
        },
        "title": {
            "display": true,
            "text": ""
        },
        "tooltips": {
            "mode": "x",
            "intersect": false
        },
        "hover": {
            "animationDuration": 0
        },
        "scales": {
            "xAxes": [
                {
                    "gridLines": {
                        "display": false
                    },
                    "display": true,
                    "scaleLabel": {
                        "display": true,
                        "labelString": ""
                    },
                    "ticks": {
                        "beginAtZero": true
                    },
                    "stacked": false
                }
            ],
            "yAxes": [
                {
                    "gridLines": {
                        "display": true
                    },
                    "display": true,
                    "scaleLabel": {
                        "display": true,
                        "labelString": ""
                    },
                    "ticks": {
                        "beginAtZero": true
                    },
                    "stacked": false
                }
            ]
        },
        "plugins": {
            "zoom": {
                "pan": {
                    "enabled": true,
                    "mode": "xy"
                },
                "zoom": {
                    "enabled": true,
                    "mode": "xy"
                }
            }
        },
        "animation": {
            "duration": 0
        },
        "responsiveAnimationDuration": 0
    },
    "autoUpdate": false,
    "allData": false,
    "showDatalabels": true,
    "timeDurationX": false,
    "timeDurationY": false
}

export const FILTER_STATES = ["IDLE", "WORKING", "MANEUVERING", "PARADA", "FALTA_VACIO"];

export const ACTIVITY_KEYS = {
    "HARVEST": "HARVEST"
}

export const SECTOR_KEY = {
    "NO_SECTOR": "NO_SECTOR",
    "HARVEST": "HARVEST"
}


export const ACTIVITY_FILTER = {
    id: 'activity',
    name: i18n.t('headers.activity'),
    show: false,
    disabled: false,
    showDialog: false,
    singleSelect: true,
    data: [],
    selectedData: [],
    selectAction: undefined
};
export const THING_TYPE_FILTER = {
    id: 'thingType',
    name: i18n.t('headers.thingType'),
    show: false,
    disabled: true,
    showDialog: false,
    singleSelect: true,
    data: [],
    selectedData: [],
    selectAction: undefined
};
export const WORK_FRONT_FILTER = {
    id: 'workFrontFilter',
    name: i18n.t('headers.workFront'),
    show: false,
    disabled: true,
    showDialog: false,
    singleSelect: false,
    data: [],
    selectedData: [],
    selectAction: undefined
};
export const STATE_FILTER = {
    id: 'state',
    name: i18n.t('headers.state'),
    show: false,
    disabled: false,
    showDialog: false,
    singleSelect: true,
    data: [],
    selectedData: [],
    selectAction: undefined
};
export const REPORT_BY_WORK_FRONT_FILTER = {
    id: 'byWorkFront',
    name: i18n.t('objectiveTendency.harvestProgress.generateReportBy'),
    show: false,
    disabled: false,
    showDialog: false,
    singleSelect: true,
    data: [{ id: "THING", key: "THINGS", name: i18n.t("objectiveTendency.tendencyReport.details.byThings") }, { id: "WORK_FRONT", key: "WORK_FRONT", name: i18n.t("objectiveTendency.tendencyReport.details.byWorkFront") }],
    selectedData: [],
    selectAction: undefined
};

export const CONFIGURATION_KEY = "home.objectiveAndTendency.harvestProgress";