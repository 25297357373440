import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import selectorService from '@/business/selectorService'
import i18n from '@/i18n'
import activityApi from '@/api/activity.api'
import thingApi from '@/api/thing.api'
import stateApi from '@/api/state.api'
//import mapReportApi from '@/api/mapReport.api'
import { configurationService } from '@/business/configurationService'
import { mapCurrentStateService } from '../../../../business/mapCurrentStateService'
import { filterRulesMapped } from '@/tools/filterRules'
import objectiveTendencyConstants from "@/constants/objectiveTendency.constants"

export default {
  name: 'OfflineHarvestTableComponent',
  components: {
    SelectorComponent,
  },
  props: {
  },
  data: () => ({
    drawer: false,
    tableComponentId: "objectiveOfflineHarvest",
    loadingTable: false,
    tableDataKey: '_id',
    tableFilterRules: {
      workFrontName: filterRulesMapped.TEXT_FIELD,
      thingName: filterRulesMapped.TEXT_FIELD,
      state: filterRulesMapped.TEXT_FIELD,
      trackTimestamp: filterRulesMapped.TEXT_FIELD,
      timestamp: filterRulesMapped.TEXT_FIELD,
    },
    tableHeaders: [
      {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("objectiveTendency.offlineHarvesters.headers.workFront"),
          type: "STRING",
          value: "workFrontName",
      },
      {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("objectiveTendency.offlineHarvesters.headers.thing"),
          type: "STRING",
          value: "thingName",
      },
      {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("objectiveTendency.offlineHarvesters.headers.state"),
          type: "STRING",
          value: "stateName",
      },
      {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("objectiveTendency.offlineHarvesters.headers.trackTimestamp"),
          type: "STRING",
          value: "trackTimestamp",
          cellConfig: {
              round: true, 
              color: "trackTimestampColor"
          },
          cellType: "TEXT_COLOR_BACKGROUND_WHITE"
      },
      {
          align: "left",
          filterType: "TEXT_FIELD",
          selected: true,
          sortable: true,
          spinner: false,
          text: i18n.t("objectiveTendency.offlineHarvesters.headers.timestamp"),
          type: "STRING",
          value: "timestamp",
          cellConfig: {
              round: true, 
              color: "timestampColor"
          },
          cellType: 'TEXT_COLOR_BACKGROUND_WHITE',
      },
    ],
    tableData: [],
    selectorModel: selectorService.getSelectorModel(false),
    activityFilter: {
      id: 'activity',
      name: i18n.t('headers.activity'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    thingTypeFilter: {
      id: 'thingType',
      name: i18n.t('headers.thingType'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    workFrontFilter: {
      id: 'workFront',
      name: i18n.t('headers.workFront'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    stateFilter: {
      id: 'state',
      name: i18n.t('headers.state'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    interval: null,
    configAttributes: null,
  }),
  created () {
  },
  async mounted () {
   
    this.selectorModel.filters.push(this.activityFilter, this.thingTypeFilter, this.workFrontFilter, this.stateFilter)
    this.selectorModel.showTimeFormatSelector = false
    this.selectorModel.showDateAndTimeSelector = false
    this.selectorModel.hideAutoReport = true

    // Al seleccionar una actividad, se habilita el filtro de equipo
    this.activityFilter.selectAction = this.activityFilterCallback.bind(this)
    // Al seleccionar un equipo, se habilita el boton de generar reporte
    this.thingTypeFilter.selectAction = this.thingTypeFilterCallback.bind(this)

    const config = await configurationService.get(this.tableComponentId)

    const configAttributes = config && config.data
    this.configAttributes = configAttributes
    this.initAutoReport()
    this.getStateTypes()

    if (configAttributes) {
      if (configAttributes.activity.length) {
        this.activityFilter.selectedData = config.data.activity
        await this.activityFilterCallback()
      }
  
      if (configAttributes.thingType.length) {
        this.thingTypeFilter.selectedData = config.data.thingType
        await this.thingTypeFilterCallback()
      }
  
      if (configAttributes.workFronts.length) {
        this.workFrontFilter.selectedData = config.data.workFronts
      }
  
      if (configAttributes.states.length) {
        this.stateFilter.selectedData = config.data.states
      }
  
      if (configAttributes.activity.length && configAttributes.thingType.length) {
        await this.getReport()
      }
    }

    const { data: activities } = await activityApi.getAllForSelector()
    
    const harvestActivities = activities.filter(activity => activity.name.includes('COSECHA') && activity.name !== 'FRENTE DE COSECHA' )

    harvestActivities.forEach(activity => {
      activity.name = this.$t(`mapReport.ACTIVITIES.${activity.key}`);
    })

    this.activityFilter.data.cleanAndUpdate(harvestActivities)
  },
  
  beforeDestroy () {
    clearInterval(this.interval)
  },

  computed: {},
  methods: {
    selector() {
      this.drawer = !this.drawer
    },
    async activityFilterCallback() {
      if (this.activityFilter.selectedData.length === 0) {
        this.thingTypeFilter.selectedData = []
        this.thingTypeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        return
      }
      this.thingTypeFilter.selectedData = []
      this.thingTypeFilter.disabled = false
      this.workFrontFilter.selectedData = []
      this.workFrontFilter.disabled = true
      const activityId = this.activityFilter.selectedData[0].id
      const thingTypesResponse = await thingApi.getThingTypesByActivity([activityId], 'HARVEST')
      this.thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data)
    },
    async thingTypeFilterCallback() {
      if (this.thingTypeFilter.selectedData.length === 0) {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        return
      }
      this.selectorModel.disableGetReportButton = false
      this.workFrontFilter.selectedData = []
      this.workFrontFilter.disabled = false
      const thingTypeId = this.thingTypeFilter.selectedData[0].id
      const activityId = this.activityFilter.selectedData[0].id
      const thingTypesResponse = await thingApi.getWorkFrontsByTypes([thingTypeId], 'HARVEST', activityId)
      this.workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data)
    },
    async getStateTypes() {
      const stateKeys = ["IDLE", "WORKING", "MANEUVERING", "PARADA", "FALTA_VACIO"]
      const statesTypesResponse = await stateApi.getStatesTypeByKey(stateKeys) 
      const statesTypesKeys = Object.keys(statesTypesResponse)
      const statesTypesMapped = statesTypesKeys.map(stateTypeKey => {
        const stateType = statesTypesResponse[stateTypeKey]
        return {
          id: stateType._id,
          name: stateType.name[i18n.locale],
          key: stateType.key,
        }
      })
      await this.stateFilter.data.cleanAndUpdate(statesTypesMapped)
    },
    async getReport() {
      this.loadingTable = true

      const filters = {
        activity: this.activityFilter.selectedData[0].id,
        thingType: this.thingTypeFilter.selectedData[0].key,
        workFronts: this.workFrontFilter.selectedData.map(workFront => ({
          workFrontName: workFront.name,
          workFrontIdThings: workFront.children ? workFront.children.map(child => child.id) : (workFront.id ? [workFront.id] : [])
        })
        ),
        states: this.stateFilter.selectedData.map(state => state.key),
      }
      
      if (this.drawer) {
        this.selector()
      }
      const offlineHarvesters = await mapCurrentStateService.getOfflineHarvesters(filters.activity, filters.thingType, filters.workFronts, filters.states)
      this.loadingTable = false
      this.getTableData(offlineHarvesters.data)
      configurationService.save(this.tableComponentId, {
        activity: this.activityFilter.selectedData,
        thingType: this.thingTypeFilter.selectedData,
        workFronts: this.workFrontFilter.selectedData,
        states: this.stateFilter.selectedData,
      })
    },

    getTableData(offlineHarvestersData) {
      let tableDataResult = offlineHarvestersData.map(data =>{
        return {
          stateName: this.getStateName(data.state.type.key),
          ...data
        }
      })
      this.tableData.cleanAndUpdate(tableDataResult);
    },
    getStateName(key) {
      if(this.stateFilter.data){
        let d = null;
        for (let i = 0; i < this.stateFilter.data.length; i++){
          d= this.stateFilter.data[i];
          if(d.key == key){
            return d.name ;
          }
      }
      }else{
        return ''; 
      }
    },
     initAutoReport (){
      const that = this
        this.interval = setInterval(()=>{
          if (that.configAttributes.activity.length && that.configAttributes.thingType.length){ 
            that.getReport()
          }
        },objectiveTendencyConstants.linkedTrucksTableRefreshInterval)
      }

  }
}
