//import mapReportApi from "@/api/mapReport.api"
import { mapCurrentStateService } from "../../../../business/mapCurrentStateService"
import objectiveTendencyConstants from "@/constants/objectiveTendency.constants"
import i18n from '@/i18n'
import { filterRulesMapped } from '@/tools/filterRules'

export default {
  name: 'LinkedTrucksTableComponent',
  components: {
  },
  props: {
  },
  data: () => ({
    drawer: false,
    loadingTable: true,
    tableDataKey: '_id',
    tableFilterRules: {
      workFrontName: filterRulesMapped.TEXT_FIELD,
      thingName: filterRulesMapped.TEXT_FIELD,
      trackTimestamp: filterRulesMapped.TEXT_FIELD,
    },
    tableHeaders: [
      {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("linkedTrucks.headers.workFront"),
        type: "STRING",
        value: "workFrontName",
      },
      {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("linkedTrucks.headers.thingName"),
        type: "STRING",
        value: "thingName",
      },
      {
        align: "left",
        filterType: "TEXT_FIELD",
        selected: true,
        sortable: true,
        spinner: false,
        text: i18n.t("linkedTrucks.headers.trackTimestamp"),
        type: "STRING",
        value: "trackTimestamp",
      },
    ],
    tableData: [],
    interval: null,
  }),
  created () {
  },
  mounted () {
    clearInterval(this.interval)
    this.getReport()
    this.interval = setInterval(()=> {this.getReport()}, objectiveTendencyConstants.linkedTrucksTableRefreshInterval)
  },

  computed: {},
  methods: {

    async getReport() {
      this.loadingTable = true
      const vinculatedTrucksResponse = await mapCurrentStateService.getVinculatedTrucks()
      this.loadingTable = false
      this.tableData.cleanAndUpdate(vinculatedTrucksResponse.data)
    },

  },
  destroyed() {
    clearInterval(this.interval)
  }
}

