import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import businessTargetService from '../../../../business/businessTargetService'
import selectorService from '@/business/selectorService'
import i18n from '@/i18n'
import thingApi from '@/api/thing.api'
import activityApi from '@/api/activity.api'
import { configurationService } from '@/business/configurationService'
import objectiveTendencyConstants from "@/constants/objectiveTendency.constants"

export default {
  name: 'HarvestFrontTargetComponent',
  components: {
    SelectorComponent,
  },
  props: {
  },
  data: () => ({
    tableDataKey: '_id',
    tableData: [],
    selectorModel: selectorService.getSelectorModel(false),
    tableFilterRules: {},
    loadingTable: false,
    drawer: false,
    interval: null,
    configAttributes: null,
    tableComponentId: "harvestFrontTargetTable",
    tableHeaders:[
        {
            align: "left",
            filterType: "TEXT_FIELD",
            selected: true,
            sortable: true,
            spinner: false,
            text:  i18n.t("objectiveTendency.workFrontStateTargets.headers.state"),
            type: "STRING",
            value: "stateTargetName",
        },
        {
            align: "right",
            filterType: "TEXT_FIELD",
            selected: true,
            sortable: true,
            spinner: false,
            text: "%",
            type: "STRING",
            value: "percentage",
        },
        {
            align: "right",
            filterType: "TEXT_FIELD",
            selected: true,
            sortable: true,
            spinner: false,
            text: i18n.t("objectiveTendency.workFrontStateTargets.headers.workFront"),
            type: "STRING",
            value: "workFrontValue",
        },
        {
            align: "right",
            filterType: "TEXT_FIELD",
            selected: true,
            sortable: true,
            spinner: false,
            text: i18n.t("objectiveTendency.workFrontStateTargets.headers.thing"),
            type: "STRING",
            value: "thingValue",
        }
    ],
    workFrontFilter: {
        id: 'workFront',
        name: i18n.t('headers.workFront'),
        show: false,
        disabled: true,
        showDialog: false,
        singleSelect: true,
        data: [],
        selectedData: [],
        selectAction: undefined
      },
      activityFilter: {
        id: 'activity',
        name: i18n.t('headers.activity'),
        show: false,
        disabled: false,
        showDialog: false,
        singleSelect: true,
        data: [],
        selectedData: [],
        selectAction: undefined
      },
      thingTypeFilter: {
        id: 'thingType',
        name: i18n.t('headers.thingType'),
        show: false,
        disabled: true,
        showDialog: false,
        singleSelect: true,
        data: [],
        selectedData: [],
        selectAction: undefined
      },
  }),
  created () {
  },
  async mounted () {
    this.selectorModel.filters.push(this.activityFilter, this.thingTypeFilter, this.workFrontFilter)
    this.selectorModel.showTimeFormatSelector = false
    this.selectorModel.showDateAndTimeSelector = false
    this.selectorModel.hideAutoReport = true

    const config = await configurationService.get(this.tableComponentId)

    const configAttributes = config && config.data
    this.configAttributes = configAttributes
    this.initAutoReport()

    
    if (configAttributes) {
      if (configAttributes.activity.length) {
        this.activityFilter.selectedData = config.data.activity
        await this.activityFilterCallback()
      }
  
      if (configAttributes.thingType.length) {
        this.thingTypeFilter.selectedData = config.data.thingType
        await this.thingTypeFilterCallback()
      }
  
      if (configAttributes.workFronts.length) {
        this.workFrontFilter.selectedData = config.data.workFronts
      }

      if (configAttributes.activity.length && configAttributes.thingType.length && configAttributes.workFronts.length) {
        await this.getReport()
      }
    }

    // Al seleccionar una actividad, se habilita el filtro de equipo
    this.activityFilter.selectAction = this.activityFilterCallback.bind(this)
    // Al seleccionar un equipo, se habilita el boton de generar reporte
    this.thingTypeFilter.selectAction = this.thingTypeFilterCallback.bind(this)

    const { data: activities } = await activityApi.getAllForSelector()
    
    const harvestActivities = activities.filter(activity => activity.name.includes('COSECHA') && activity.name !== 'FRENTE DE COSECHA' )
    harvestActivities.forEach(activity => {
      activity.name = this.$t(`mapReport.ACTIVITIES.${activity.key}`);
    })
    this.activityFilter.data.cleanAndUpdate(harvestActivities)    
  },
  
  beforeDestroy () {
    
  },

  computed: {},
    methods: {
        selector() {
        this.drawer = !this.drawer
        },
    
        async getReport() {
            this.loadingTable = true
            if (this.drawer) {
              this.selector()
            }
            let workFrontIdThings = this.workFrontFilter.selectedData.map(workFront => ( workFront.children ? workFront.children.map(child => child.id) : []))
            await businessTargetService.getWorkFrontTargetData(workFrontIdThings[0]).then(response=>{
                this.tableData.cleanAndUpdate(response)
            });

            this.loadingTable = false
            configurationService.save(this.tableComponentId, {
              activity: this.activityFilter.selectedData,
              thingType: this.thingTypeFilter.selectedData,
              workFronts: this.workFrontFilter.selectedData,
            })
    
        },
        async activityFilterCallback() {
            if (this.activityFilter.selectedData.length === 0) {
              this.thingTypeFilter.selectedData = []
              this.thingTypeFilter.disabled = true
              this.workFrontFilter.selectedData = []
              this.workFrontFilter.disabled = true
              return
            }
            this.thingTypeFilter.selectedData = []
            this.thingTypeFilter.disabled = false
            this.workFrontFilter.selectedData = []
            this.workFrontFilter.disabled = true
            const activityId = this.activityFilter.selectedData[0].id
            const thingTypesResponse = await thingApi.getThingTypesByActivity([activityId], 'HARVEST')
            this.thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data)
          },
          async thingTypeFilterCallback() {
            if (this.thingTypeFilter.selectedData.length === 0) {
              this.workFrontFilter.selectedData = []
              this.workFrontFilter.disabled = true
              return
            }
            this.selectorModel.disableGetReportButton = false
            this.workFrontFilter.selectedData = []
            this.workFrontFilter.disabled = false
            const thingTypeId = this.thingTypeFilter.selectedData[0].id
            const activityId = this.activityFilter.selectedData[0].id
            const thingTypesResponse =
            await thingApi.getWorkFrontsByTypes([thingTypeId], 'HARVEST', activityId)
            this.workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data)
          },

          initAutoReport (){
            const that = this
              this.interval = setInterval(()=>{
                if (that.configAttributes.activity.length && that.configAttributes.thingType.length){ 
                  that.getReport()
                }
              },objectiveTendencyConstants.linkedTrucksTableRefreshInterval)
          }


    }
}
