import activityApi from '@/api/activity.api';

import thingApi from '@/api/thing.api';
import { configurationService } from '@/business/configurationService';
import selectorService from '@/business/selectorService';
import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue';
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue';
import i18n from '@/i18n';
import stateService from '@/business/state.service';
import { CustomTimeRangeTypes, TimeRanges } from '@colven/common-domain-lib/lib';
import objectiveTendencyService from '@/business/objectiveTendency.service';
import { copyJson, getTimeRangeNames } from '@/tools/functions';
import { ACTIVITY_FILTER, CONFIGURATION_KEY, REPORT_BY_WORK_FRONT_FILTER, SECTOR_KEY, STATE_FILTER, THING_TYPE_FILTER, WORK_FRONT_FILTER } from './harvestProgressConstants';

export default {
  name: 'HarvestProgressComponent',
  components: {
    SelectorComponent,
    DashboardChartContainer
  },
  props: {
  },
  data: () => ({
    intervalReport: null,
    reportData: null,
    drawer: false,
    selectorModel: selectorService.getSelectorModel(false),
    dashboardModel: {
      filters: [
      ],
      charts: [],
      disableSaveButton: true,
      selectorData: [{ id: "all" }],
      percentageSwitchModel: false,
      showDateAndTimeSelector: true,
      selectedDateAndTimeRange: TimeRanges.ENTERPRISE,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      customDateTimeValidForm: true,
      dateAndTimeRanges: [
        {
          id: TimeRanges.ENTERPRISE,
          text: i18n.t('selector.dateAndTime.dailyShift'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE_PREVIOUS,
          text: i18n.t('selector.dateAndTime.previousShift'),
          custom: false
        },
        {
          id: TimeRanges.CUSTOM,
          text: i18n.t('selector.dateAndTime.custom'),
          custom: true
        }
      ],
      dateAndTimeCustomRangeTypes: [
        {
          text: i18n.t('selector.dateAndTime.customTypes.date'),
          value: CustomTimeRangeTypes.DATE
        }
      ],
    },
    chartConfiguration: [],
    getDetails: null,
    loading: false,
    chartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    activityFilter: copyJson(ACTIVITY_FILTER),
    thingTypeFilter: copyJson(THING_TYPE_FILTER),
    workFrontFilter: copyJson(WORK_FRONT_FILTER),
    stateFilter: copyJson(STATE_FILTER),
    byWorkFrontFilter: copyJson(REPORT_BY_WORK_FRONT_FILTER),
    chartRenderKey: (Math.random() + 1).toString(36).substring(7),
    thingsSelected: [],
    thingsWithoutStateTargets: [],
  }),
  created() {
  },
  async mounted() {
    this.dashboardModel.filters.push(this.byWorkFrontFilter, this.activityFilter, this.thingTypeFilter, this.workFrontFilter, this.stateFilter);
    this.selectorModel.showTimeFormatSelector = false;
    // Al seleccionar una actividad, se habilita el filtro de equipo
    this.activityFilter.selectAction = this.activityFilterCallback.bind(this);
    // Al seleccionar un equipo, se habilita el boton de generar reporte
    this.thingTypeFilter.selectAction = this.thingTypeFilterCallback.bind(this);

    this.byWorkFrontFilter.selectAction = this.reportByWorkFrontOnChange.bind(this);

    this.stateFilter.selectAction = this.validateSelector.bind(this);

    this.workFrontFilter.selectAction = this.validateSelector.bind(this);

    const { data: activities } = await activityApi.getAllForSelector();
    const harvestActivities = activities//activities.filter(activity => activity.key === ACTIVITY_KEYS.HARVEST);

    harvestActivities.forEach(activity => {
      activity.name = this.$t(`mapReport.ACTIVITIES.${activity.key}`);
    })
    
    this.activityFilter.data.cleanAndUpdate(harvestActivities);
    this.getStateTypes()
    this.setConfiguration();
  },
  computed: {
            /**
     * Detalles del gráfico (html)
     */
      details () {
        const activity = this.activityFilter.selectedData && this.activityFilter.selectedData.length ? this.activityFilter.selectedData[0].name : ""
        const thingType = this.thingTypeFilter.selectedData && this.thingTypeFilter.selectedData.length ? this.thingTypeFilter.selectedData[0].name : ""
        const workFronts = this.workFrontFilter.selectedData && this.workFrontFilter.selectedData.length ? this.workFrontFilter.selectedData.map(workFront => workFront.name) : ""
        const states = this.stateFilter.selectedData && this.stateFilter.selectedData.length ? this.stateFilter.selectedData.map(state => state.name) : ""
        const timeNames = getTimeRangeNames()
        const byWorkFront = this.byWorkFrontFilter.selectedData && this.byWorkFrontFilter.selectedData.length ? this.byWorkFrontFilter.selectedData[0].name : ""
  
        let details = `<h3>${this.$t('chart.chart')}: ${this.$t('objectiveTendency.harvestProgress.title')}</h3>`
        details += `<h5>${this.$t('objectiveTendency.tendencyReport.details.reportBy')}: ${byWorkFront}</h5>`
        details += `<h5>${this.$t('activity')}: ${activity}</h5>`
        details += `<h5>${this.$t('type')}: ${thingType}</h5>`
        details += `<h5>${this.$t('headers.workFront')}: ${workFronts}</h5>`
        details += `<h5>${this.$t('headers.state')}: ${states}</h5>`
        details += `<h5>${this.$t('selector.dateAndTime.label')}: ${timeNames[this.dashboardModel.selectedDateAndTimeRange]}</h5>`
        return details
      }
  },
  methods: {
    selector() {
      this.drawer = !this.drawer
    },
    dateSelectorChanged() {
      this.validateSelector();
    },
    async validateSelector() {
      await new Promise(resolve => setTimeout(resolve, 10));
      const customTimeValidation = (
        this.dashboardModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM || !!(this.dashboardModel.sinceDate && this.dashboardModel.toDate && this.dashboardModel.customDateTimeValidForm)
      );
      const hasActivity = (this.activityFilter.selectedData && this.activityFilter.selectedData.length > 0);
      const hasType = (this.thingTypeFilter.selectedData && this.thingTypeFilter.selectedData.length > 0);
      const hasReportType = (this.byWorkFrontFilter.selectedData && this.byWorkFrontFilter.selectedData.length > 0);
      const hasState = (this.stateFilter.selectedData && this.stateFilter.selectedData.length > 0);
      const hasWorkFront = (this.workFrontFilter.selectedData && this.workFrontFilter.selectedData.length > 0);
      this.dashboardModel.disableSaveButton = !(customTimeValidation && hasActivity && hasType && hasReportType && hasState && hasWorkFront);
    },
    async setConfiguration() {
      let config = await configurationService.get(CONFIGURATION_KEY);
      if (config && config.data) {
        config = config.data;
        this.activityFilter.selectedData = config.activities;
        this.thingTypeFilter.selectedData = config.types;
        this.byWorkFrontFilter.selectedData = config.reportType;
        this.workFrontFilter.selectedData = config.things;
        this.stateFilter.selectedData = config.state;
        if (this.activityFilter.selectedData && this.activityFilter.selectedData.length > 0) {
          const activityId = this.activityFilter.selectedData[0].id
          const thingTypesResponse = await thingApi.getThingTypesByActivity([activityId], SECTOR_KEY.NO_SECTOR)
          this.thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data)
          this.thingTypeFilter.disabled = false;
        }
        if (this.thingTypeFilter.selectedData && this.thingTypeFilter.selectedData.length > 0) {
          await this.setThingTypeFilter();
        }
        if (config.selectedDateAndTimeRange && this.dashboardModel.dateAndTimeRanges.find(range => range.id === config.selectedDateAndTimeRange)) {
          this.dashboardModel.selectedDateAndTimeRange = config.selectedDateAndTimeRange
          // custom
          if (config.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
            if (config.selectedDateAndTimeRangeCustomType &&
              config.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE) {
              this.dashboardModel.selectedDateAndTimeRangeCustomType = config.selectedDateAndTimeRangeCustomType
              if (config.sinceDate && config.toDate) {
                this.dashboardModel.sinceDate = config.sinceDate
                this.dashboardModel.toDate = config.toDate
              }
            } else if (config.selectedDateAndTimeRangeCustomType &&
              config.selectedDateAndTimeRangeCustomType === CustomTimeRangeTypes.DATE_AND_TIME) {
              this.dashboardModel.selectedDateAndTimeRangeCustomType = config.selectedDateAndTimeRangeCustomType
              if (config.sinceDate && config.toDate && config.sinceTime && config.toTime) {
                this.dashboardModel.sinceDate = config.sinceDate
                this.dashboardModel.sinceTime = config.sinceTime
                this.dashboardModel.toDate = config.toDate
                this.dashboardModel.toTime = config.toTime
              }
            }
          }
        }

        if (config.activities &&
          config.types &&
          config.reportType &&
          config.things &&
          config.state &&
          config.selectedDateAndTimeRange) {
            this.generateReport(this.dashboardModel)
          }
      }
    },
    async setThingTypeFilter() {
      const thingTypeId = this.thingTypeFilter.selectedData[0].id;
      const activityId = this.activityFilter.selectedData[0].id;
      const thingTypesResponse = await thingApi.getWorkFrontsByTypes([thingTypeId], null, activityId);
      if (this.byWorkFrontFilter.selectedData && this.byWorkFrontFilter.selectedData.length > 0) {
        this.workFrontFilter.disabled = false;
      } else {
        this.workFrontFilter.disabled = true;
      }
      this.workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data);
      if (this.byWorkFrontFilter.selectedData[0].id === "WORK_FRONT") {
        this.workFrontFilter.dataTemp = copyJson(this.workFrontFilter.data);
        this.workFrontFilter.data.cleanAndUpdate(objectiveTendencyService.getWorkFrontToSelect(this.workFrontFilter.data));
      }
      this.validateSelector();
    },
    async getStateTypes() {
      const statesTypesResponse = await stateService.getStatesTypesByEnterpriseId();
      let lang = localStorage.getItem('locale') || i18n.locale.split('-')[0]
      const statesTypes = statesTypesResponse.map(item => {
        return {
          id: item._id,
          name: item.name[lang],
          key: item.key,
        };
      })
      this.stateFilter.data.cleanAndUpdate(statesTypes);
    },
    reportByWorkFrontOnChange() {
      if (this.byWorkFrontFilter.selectedData.length != 0) {
        this.workFrontFilter.selectedData = []
        if (this.workFrontFilter.data && this.workFrontFilter.data.length > 0) {
          if (this.byWorkFrontFilter.selectedData[0].id === "WORK_FRONT") {
            this.workFrontFilter.dataTemp = copyJson(this.workFrontFilter.data);
            this.workFrontFilter.data.cleanAndUpdate(objectiveTendencyService.getWorkFrontToSelect(this.workFrontFilter.data));
          } else {
            this.workFrontFilter.data.cleanAndUpdate(this.workFrontFilter.dataTemp);
          }
          this.workFrontFilter.disabled = false;
        }
      } else {
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
      }
      this.validateSelector();
    },
    async activityFilterCallback() {
      if (this.activityFilter.selectedData.length === 0) {
        this.thingTypeFilter.selectedData = []
        this.thingTypeFilter.disabled = true
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        return
      }
      this.thingTypeFilter.selectedData = []
      this.thingTypeFilter.disabled = false
      this.workFrontFilter.selectedData = []
      this.workFrontFilter.disabled = true
      const activityId = this.activityFilter.selectedData[0].id
      const thingTypesResponse = await thingApi.getThingTypesByActivity([activityId], SECTOR_KEY.NO_SECTOR)
      this.thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data);
      this.validateSelector();
    },
    async thingTypeFilterCallback() {
      if (this.thingTypeFilter.selectedData.length === 0) {
        this.workFrontFilter.selectedData = []
        this.workFrontFilter.disabled = true
        return
      }
      this.selectorModel.disableGetReportButton = false
      this.workFrontFilter.selectedData = []
      await this.setThingTypeFilter();
      this.validateSelector();
    },
    async onPercentageChange() {
      if (this.reportData) {
        await this.getReport(this.dashboardModel);
      }
    },
    async generateReport(filters) {
      if (this.intervalReport) {
        clearInterval(this.intervalReport);
      }
      if (filters.selectedDateAndTimeRange === TimeRanges.ENTERPRISE) {
        this.intervalReport = setInterval(async () => {
          await this.getReport(filters);
        }, 60000 * 5);
      }
      await this.saveConfig(filters);
      await this.getReport(filters);
    },
    async getReport(data) {
      this.loading = true;
      this.reportData = null;
      this.dashboardModel.charts.clean();
      try {
        data.percentageResult = this.dashboardModel.percentageSwitchModel;
        const { charts, thingsWithoutStateTargets } = await objectiveTendencyService.getHarvestProgressChart(data);
        this.thingsWithoutStateTargets = thingsWithoutStateTargets;
        this.reportData = charts;
        this.dashboardModel.charts.cleanAndUpdate(charts);
        
        this.chartRenderKey = (Math.random() + 1).toString(36).substring(7);
      } finally {
        this.loading = false;
      }
    },
    async saveConfig(filters) {
      const [reportType, activityFilter, thingTypeFilter, thingsFilter, stateFilter] = filters.filters;
      await configurationService.save(CONFIGURATION_KEY,
        {
          activities: activityFilter.selectedData,
          types: thingTypeFilter.selectedData,
          reportType: reportType.selectedData,
          things: thingsFilter.selectedData,
          state: stateFilter.selectedData,
          selectedDateAndTimeRange: filters.selectedDateAndTimeRange,
          selectedDateAndTimeRangeCustomType: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.selectedDateAndTimeRangeCustomType : null,
          sinceDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceDate : null,
          sinceTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.sinceTime : null,
          toDate: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toDate : null,
          toTime: filters.selectedDateAndTimeRange === TimeRanges.CUSTOM ? filters.toTime : null
        })
    }
  },
  beforeDestroy() {
    if (this.intervalReport) {
      clearInterval(this.intervalReport);
    }
  }
}