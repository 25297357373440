const objectiveTendencyConstants = {
  linkedTrucksTableRefreshInterval: 300000,
  harvestConectionState:{
    high: "offline-high",
    medium: "offline-medium",
    connected: "online"
  },
  SECTOR_KEY: {
    NO_SECTOR: "NO_SECTOR",
}
}


export default objectiveTendencyConstants