import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue'
import i18n from '@/i18n'
import activityApi from '@/api/activity.api'
import thingApi from '@/api/thing.api'
import stateService from '@/business/state.service'
import { configurationService } from '@/business/configurationService'
import harvestTargetService from '@/business/harvestTargetService'
import entepriseService from '../../../../business/enteprise.service'
import { Chart, ChartType, Dataset, Options, TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import enterpriseApi from '@/api/enterprise.api'
import { getDateTimeRange, getTimeRangeNames } from '@/tools/functions'
import moment from 'moment'
import objectiveTendencyService from '@/business/objectiveTendency.service'
import objectiveTendencyConstants from '@/constants/objectiveTendency.constants'


const REFRESH = 5

export default {
  name: 'ObjectivesPerStatesComponent',
  components: {
    DashboardChartContainer,
  },
  props: {
  },
  data: () => ({
    interval: undefined,
    loading: false,
    chartComponentId: "objectivesPerHarvestChart",
    chartContainerModel: {
      // para mostrar/ocultar los filtros
      showNavigationPanel: false,
      // rangos de tiempo
      dateAndTimeRanges: [
        {
          id: TimeRanges.ENTERPRISE,
          text: i18n.t('selector.dateAndTime.dailyShift'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE_PREVIOUS,
          text: i18n.t('selector.dateAndTime.previousShift'),
          custom: false
        },
        {
          id: TimeRanges.CUSTOM,
          text: i18n.t('selector.dateAndTime.custom'),
          custom: true
        }
      ],
      //  tipos de rangos personalizados
      dateAndTimeCustomRangeTypes: [
        {
          text: i18n.t('selector.dateAndTime.customTypes.date'),
          value: CustomTimeRangeTypes.DATE
        }
      ],
      selectedDateAndTimeRange: TimeRanges.ENTERPRISE,
      customDateTimeValidForm: false,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      filters: [
        // 0. activity filter
        {
          id: 'activity',
          name: i18n.t('headers.activity'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        // 1. thingType filter
        {
          id: 'thingType',
          name: i18n.t('headers.thingType'),
          show: false,
          disabled: true,
          showDialog: false,
          singleSelect: true,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        // 2. workFront filter
        {
          id: 'workFront',
          name: i18n.t('headers.workFront'),
          show: false,
          disabled: true,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
        // 3. state filter
        {
          id: 'state',
          name: i18n.t('headers.state'),
          show: false,
          disabled: false,
          showDialog: false,
          singleSelect: false,
          data: [],
          selectedData: [],
          selectAction: undefined
        },
      ],
      selects: [],
      percentageSwitchModel: false,
      selectorData: [],
      selectorModel: null,
      charts: [],
      disableSaveButton: true,
      update: false,
    },
    thingsWithoutStateTargets: [],
    chartRenderKey: (Math.random() + 1).toString(36).substring(7),
  }),
  created() {
  },
  async mounted() {

    const activityFilter = this.chartContainerModel.filters[0]
    const thingTypeFilter = this.chartContainerModel.filters[1]
    const workFrontFilter = this.chartContainerModel.filters[2]

    // Al seleccionar una actividad, se habilita el filtro de equipo
    activityFilter.selectAction = this.activityFilterCallback.bind(this)
    // Al seleccionar un equipo, se habilita el filtro de frente de trabajo
    thingTypeFilter.selectAction = this.thingTypeFilterCallback.bind(this)
    // Al seleccionar un frente de trabajo, se habilita el boton de generar reporte
    workFrontFilter.selectAction = this.workFrontFilterCallback.bind(this)

    // tiempo de jornada diaria
    if (!JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      const enterpriseTimeSpan = (await enterpriseApi.getEnterpriseCustomTimeSpan()).data
      if (enterpriseTimeSpan) {
        localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
      } else {
        this.chartContainerModel.dateAndTimeRanges.splice(this.chartContainerModel.dateAndTimeRanges.indexOf(x => x.id === TimeRanges.ENTERPRISE) - 1, 1)
      }
    }

    await this.loadConfig()

    const { data: activities } = await activityApi.getAllForSelector()

    activities.forEach(activity => {
      activity.name = this.$t(`mapReport.ACTIVITIES.${activity.key}`);
    })

    activityFilter.data.cleanAndUpdate(activities)

    this.getStateTypes()
  },
  computed: {
    /**
     * Detalles del gráfico (html)
     */
    details() {
      const activity = this.chartContainerModel.filters[0].selectedData.length ? this.chartContainerModel.filters[0].selectedData[0].name : ""
      const thingType = this.chartContainerModel.filters[1].selectedData.length ? this.chartContainerModel.filters[1].selectedData[0].name : ""
      const workFronts = this.chartContainerModel.filters[2].selectedData.length ? this.chartContainerModel.filters[2].selectedData.map(workFront => workFront.name) : ""
      const states = this.chartContainerModel.filters[3].selectedData.length ? this.chartContainerModel.filters[3].selectedData.map(state => state.name) : ""
      const timeNames = getTimeRangeNames()

      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('objectiveTendency.objectivesPerStates.title')}</h3>`
      details += `<h5>${this.$t('activity')}: ${activity}</h5>`
      details += `<h5>${this.$t('type')}: ${thingType}</h5>`
      details += `<h5>${this.$t('headers.workFront')}: ${workFronts}</h5>`
      details += `<h5>${this.$t('headers.state')}: ${states}</h5>`
      details += `<h5>${this.$t('selector.dateAndTime.label')}: ${timeNames[this.chartContainerModel.selectedDateAndTimeRange]}</h5>`
      return details
    }
  },
  methods: {
    saveConfig() {
      const activityFilter = this.chartContainerModel.filters[0]
      const thingTypeFilter = this.chartContainerModel.filters[1]
      const workFrontFilter = this.chartContainerModel.filters[2]
      const stateFilter = this.chartContainerModel.filters[3]

      configurationService.save(this.chartComponentId, {
        date: {
          type: this.chartContainerModel.selectedDateAndTimeRange,
          sinceDate: this.chartContainerModel.sinceDate,
          toDate: this.chartContainerModel.toDate
        },
        activity: activityFilter.selectedData,
        thingType: thingTypeFilter.selectedData,
        workFronts: workFrontFilter.selectedData,
        states: stateFilter.selectedData,
      })
    },
    async loadConfig() {
      const config = await configurationService.get(this.chartComponentId)

      const configAttributes = config && config.data

      if (configAttributes) {
        const activityFilter = this.chartContainerModel.filters[0]
        const thingTypeFilter = this.chartContainerModel.filters[1]
        const workFrontFilter = this.chartContainerModel.filters[2]
        const stateFilter = this.chartContainerModel.filters[3]

        if (configAttributes.date.type) {
          this.chartContainerModel.selectedDateAndTimeRange = configAttributes.date.type
          if (configAttributes.date.type === TimeRanges.CUSTOM) {
            this.chartContainerModel.sinceDate = configAttributes.date.sinceDate
            this.chartContainerModel.toDate = configAttributes.date.toDate
          }
        }

        if (configAttributes.activity.length) {
          activityFilter.selectedData = config.data.activity
          await this.activityFilterCallback()
        }

        if (configAttributes.thingType.length) {
          thingTypeFilter.selectedData = config.data.thingType
          await this.thingTypeFilterCallback()
        }

        if (configAttributes.workFronts.length) {
          workFrontFilter.selectedData = config.data.workFronts
          await this.workFrontFilterCallback()
        }

        if (configAttributes.states.length) {
          stateFilter.selectedData = config.data.states
        }

        if (configAttributes.activity.length
          && configAttributes.thingType.length
          && configAttributes.workFronts.length) {
          await this.getData()
        }
      }
    },
    async activityFilterCallback() {
      const activityFilter = this.chartContainerModel.filters[0]
      const thingTypeFilter = this.chartContainerModel.filters[1]
      const workFrontFilter = this.chartContainerModel.filters[2]
      if (activityFilter.selectedData.length === 0) {
        thingTypeFilter.selectedData = []
        thingTypeFilter.disabled = true
        workFrontFilter.selectedData = []
        workFrontFilter.disabled = true
        return
      }
      thingTypeFilter.selectedData = []
      thingTypeFilter.disabled = false
      workFrontFilter.selectedData = []
      workFrontFilter.disabled = true
      this.chartContainerModel.disableSaveButton = true
      const activityId = activityFilter.selectedData[0].id
      const thingTypesResponse = await thingApi.getThingTypesByActivity([activityId], objectiveTendencyConstants.SECTOR_KEY.NO_SECTOR)
      thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data)
    },
    async thingTypeFilterCallback() {
      const activityFilter = this.chartContainerModel.filters[0]
      const thingTypeFilter = this.chartContainerModel.filters[1]
      const workFrontFilter = this.chartContainerModel.filters[2]
      if (thingTypeFilter.selectedData.length === 0) {
        workFrontFilter.selectedData = []
        workFrontFilter.disabled = true
        return
      }
      workFrontFilter.selectedData = []
      workFrontFilter.disabled = false
      this.chartContainerModel.disableSaveButton = true
      const thingTypeId = thingTypeFilter.selectedData[0].id
      const activityId = activityFilter.selectedData[0].id
      const thingTypesResponse =
        await thingApi.getWorkFrontsByTypes([thingTypeId], null, activityId)
      workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data)
    },
    async workFrontFilterCallback() {
      const workFrontFilter = this.chartContainerModel.filters[2]
      if (workFrontFilter.selectedData.length === 0) {
        this.chartContainerModel.disableSaveButton = true
        return
      }
      this.chartContainerModel.disableSaveButton = false
    },
    async getStateTypes() {
      const stateFilter = this.chartContainerModel.filters[3]
      const statesTypesResponse = await stateService.getStatesTypesByEnterpriseId();
      let lang = localStorage.getItem('locale') || i18n.locale.split('-')[0]
      const statesTypes = statesTypesResponse.map(item => {
        return {
          id: item._id,
          name: item.name[lang],
          key: item.key,
        };
      })
      stateFilter.data.cleanAndUpdate(statesTypes);
    },
    async getData() {
      this.chartContainerModel.charts.clean();
      const activityFilter = this.chartContainerModel.filters[0]
      const workFrontFilter = this.chartContainerModel.filters[2]
      const stateFilter = this.chartContainerModel.filters[3]
      this.loading = true

      const conversion = this.getDateTimeRangeWithEnterpriseTimeSpanUTC(this.chartContainerModel.selectedDateAndTimeRange, this.chartContainerModel.selectedDateAndTimeRangeCustomType,
        this.chartContainerModel.sinceDate, this.chartContainerModel.sinceTime, this.chartContainerModel.toDate, this.chartContainerModel.toTime)

      const { situationByState, thingsWithoutStateTargets, colorIfExistsOfflineHarvester } = await harvestTargetService.getObjectiveProgressByState(activityFilter.selectedData[0].id, workFrontFilter.selectedData, stateFilter.selectedData, conversion.tFrom, conversion.tTo)

      const colorsData = await this.getColors(situationByState);

      this.chartRenderKey = (Math.random() + 1).toString(36).substring(7);

      const formattedData = await this.formatDataForPieChart(situationByState, colorsData, colorIfExistsOfflineHarvester)

      const pieChart = formattedData.map((data, index) => this.getPieChart(data, index))

      this.chartContainerModel.charts.cleanAndUpdate(pieChart)

      this.loading = false

      this.thingsWithoutStateTargets = thingsWithoutStateTargets

      if (this.chartContainerModel.selectedDateAndTimeRange === TimeRanges.ENTERPRISE && this.interval === undefined) {
        const that = this
        that.interval = setInterval(async function () {
          await that.getData()
        }, REFRESH * 60 * 1000)
      }
    },
    getDateTimeRangeWithEnterpriseTimeSpanUTC(selectedDateAndTimeRange, selectedDateAndTimeRangeCustomType, sinceDate, sinceTime, toDate, toTime) {
      if (selectedDateAndTimeRange === TimeRanges.CUSTOM) {
        const enterpriseTimeSpanUTC = entepriseService.getEnterpriseTimeSpanUTC();
        const tTo = moment(toDate, 'YYYY-MM-DD').utc().set('hour', enterpriseTimeSpanUTC).unix();
        const tFrom = moment(sinceDate, 'YYYY-MM-DD').utc().set('hour', enterpriseTimeSpanUTC).unix();
        return {
          tTo,
          tFrom
        }
      } else {
        return getDateTimeRange(selectedDateAndTimeRange, selectedDateAndTimeRangeCustomType, sinceDate, sinceTime, toDate, toTime);
      }
    },
    async getColors(situationByState) {
      const colors = {};
      const statesKeys = Object.keys(situationByState)
      const thingType = this.chartContainerModel.filters[1].selectedData.length ? this.chartContainerModel.filters[1].selectedData[0].key : ""

      const result = await thingApi.getEnterpriseEventConfigurationMultipleTypes([thingType]);

      result[thingType].forEach(event => {
        statesKeys.forEach(stateKey => {
          if (event && event.stateType && event.stateType === situationByState[stateKey].stateType) {
            colors[stateKey] = event.stateColor;
          }
        })
      })
      return colors;
    },

    async formatDataForPieChart(situationByState, colorsData, colorIfExistsOfflineHarvester) {
      const labels = []

      const statesKeys = Object.keys(situationByState)

      const lang = localStorage.getItem('locale')


      statesKeys.forEach(async stateKey => {
        labels.push(situationByState[stateKey].name[lang] + ' ' + this.$t('objectiveTendency.objectivesPerStates.progress'))
        labels.push(situationByState[stateKey].name[lang] + ' ' + this.$t('objectiveTendency.objectivesPerStates.missing'))
      })

      const datasetsByWorkShifts = []
      const workShiftsCount = JSON.parse(localStorage.getItem('enterpriseTimeSpan')).shift

      // SOP-545 El orden de aparición de los colores dependía del orden en que fueran insertados en la propiedad eventConfig de la colección types
      // Se agrega el siguiente fix para reordenar los colores del mismo modo que se agregan los labels a los datasets
      const colors = [];
      statesKeys.forEach(state => {
        colors.push(colorIfExistsOfflineHarvester ? colorIfExistsOfflineHarvester : colorsData[state])
        colors.push('#A9A7A7');
      })

      const datasetsTotal = statesKeys.map((stateKey, index) => {
        const objective = situationByState[stateKey].objective
        const objectiveByWorkShifts = objective / workShiftsCount

        const dataTotal = labels.map((_label, i) => {
          const progress = situationByState[stateKey].totalProgress.toFixed(2) ? situationByState[stateKey].totalProgress.toFixed(2) : 0
          if (i === index * 2) {
            return progress
          }
          if (i === ((index * 2) + 1)) {
            return (objective - progress).toFixed(2)
          }
          return 0
        })

        situationByState[stateKey].progressByWorkShifts.forEach((progressByWorkShift, pIndex) => {
          const workShiftData = labels.map((_label, i) => {
            const progress = progressByWorkShift.progress.toFixed(2) ? progressByWorkShift.progress.toFixed(2) : 0
            if (i === index * 2) {
              return progress
            }
            if (i === ((index * 2) + 1)) {
              return (objectiveByWorkShifts - progress).toFixed(2)
            }
            return 0
          })

          datasetsByWorkShifts[pIndex] ? datasetsByWorkShifts[pIndex].push({
            data: workShiftData,
            backgroundColor: colors
          })
            : datasetsByWorkShifts.push([{
              data: workShiftData,
              backgroundColor: colors
            }])
        })
        return ({
          data: dataTotal,
          backgroundColor: colors,
        })
      })

      const datasets = [datasetsTotal, ...datasetsByWorkShifts]

      const data = datasets.map((dataset, index) => ({
        datasets: dataset,
        labels,
        name: index === 0 ? this.$t('objectiveTendency.total') : `${this.$t('objectiveTendency.shift')} ${index}`
      }))

      return data
    },
    getPieChart(chartData, index) {
      const chart = new Chart();
      // id
      chart.id = 'objectives-per-harvest-chart-pie-' + index;
      // nombre
      chart.name = chartData.name;
      // tipo
      chart.type = ChartType.PIE;
      // labels
      chart.data.labels = chartData.labels;

      // Dataset
      chartData.datasets.forEach(datasetData => {
        const dataset = new Dataset();
        dataset.data = datasetData.data;
        dataset.labels = datasetData.labels;
        // Color
        dataset.backgroundColor = datasetData.backgroundColor;

        // Agrego el dataset creado
        chart.data.datasets.push(dataset);
      })

      // Options
      const options = new Options();
      options.title.text = objectiveTendencyService.getDateTimeRangeForTitle(this.chartContainerModel);
      options.tooltips = {
        mode: 'point',

        filter(tooltipItem) {
          return chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] !== 0;
        },
        callbacks: {
          label: (data) => {
            return `${chart.data.labels[data.index]}: ${Math.abs(chartData.datasets[data.datasetIndex].data[data.index])}`;
          },
          footer() {
            return null;
          }
        }
      }
      chart.options = options;
      chart.showDatalabels = false
      return chart;
    }

  },
  destroyed() {
    clearInterval(this.interval)
  }
}
