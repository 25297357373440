import activityApi from '@/api/activity.api';
//import stateApi from '@/api/state.api';
import stateService from '@/business/state.service';
import thingApi from '@/api/thing.api';
import { configurationService } from '@/business/configurationService';
import DashboardChartContainer from '@/components/commons/dashboard-chart-container/DashboardChartContainer.vue';
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue';
import i18n from '@/i18n';
import { CustomTimeRangeTypes, TimeRanges } from '@colven/common-domain-lib/lib';
import objectiveTendencyService from '@/business/objectiveTendency.service';
import { copyJson, getTimeRangeNames } from '@/tools/functions';
import objectiveTendencyConstants from '@/constants/objectiveTendency.constants';


export default {
  name: 'TendencyPanelComponent',
  components: {
    SelectorComponent,
    DashboardChartContainer
  },
  props: {},
  data: () => ({
    intervalReport: null,
    reportData: null,
    chartComponentId: "tendencyChart",
    drawer: false,
    dashboardModel: {
      filters: [],
      charts: [],
      disableSaveButton: true,
      selectorData: [{id: "all"}],
      percentageSwitchModel: false,
      showDateAndTimeSelector: true,
      selectedDateAndTimeRange: TimeRanges.CUSTOM,
      selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
      sinceDate: null,
      sinceTime: null,
      toDate: null,
      toTime: null,
      customDateTimeValidForm: true,
      dateAndTimeRanges: [
        {
          id: TimeRanges.CUSTOM,
          text: i18n.t('selector.dateAndTime.custom'),
          custom: true
        },
        {
          id: TimeRanges.LAST_WEEK,
          text: i18n.t('selector.dateAndTime.lastWeek'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE,
          text: i18n.t('selector.dateAndTime.dailyShift'),
          custom: false
        }
      ],
      dateAndTimeCustomRangeTypes: [
        {
          text: i18n.t('selector.dateAndTime.customTypes.date'),
          value: CustomTimeRangeTypes.DATE
        },
        {
          text: i18n.t('selector.dateAndTime.customTypes.day'),
          value: 'DAY'
        }
      ],
    },
    chartConfiguration: null,
    getDetails: null,
    loading: false,
    chartComponentModel: {
      showRangeSelector: false,
      rangeSelectorData: []
    },
    activityFilter: {
      id: 'activity',
      name: i18n.t('headers.activity'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    thingTypeFilter: {
      id: 'thingType',
      name: i18n.t('headers.thingType'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    workFrontFilter: {
      id: 'workFrontFilter',
      name: i18n.t('headers.workFront'),
      show: false,
      disabled: true,
      showDialog: false,
      singleSelect: false,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    stateFilter: {
      id: 'state',
      name: i18n.t('headers.state'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [],
      selectedData: [],
      selectAction: undefined
    },
    byWorkFrontFilter: {
      id: 'byWorkFront',
      name: i18n.t('objectiveTendency.harvestProgress.generateReportBy'),
      show: false,
      disabled: false,
      showDialog: false,
      singleSelect: true,
      data: [{
        id: "THING",
        key: "THINGS",
        name: i18n.t("objectiveTendency.tendencyReport.details.byThings")
      }, {id: "WORK_FRONT", key: "WORK_FRONT", name: i18n.t("objectiveTendency.tendencyReport.details.byWorkFront")}],
      selectedData: [],
      selectAction: undefined
    },
    chartRenderKey: (Math.random() + 1).toString(36).substring(7),
    thingsSelected: [],
    colorScheme: {},
    timeRangeType: ''
  }),
  async mounted() {
    this.chartConfiguration = await configurationService.get(this.chartComponentId);
    this.dashboardModel.filters.push(this.byWorkFrontFilter, this.activityFilter, this.thingTypeFilter, this.workFrontFilter, this.stateFilter);
    // Al seleccionar una actividad se habilita el filtro de equipo
    this.activityFilter.selectAction = this.activityFilterCallback.bind(this);
    // Al seleccionar un equipo se habilita el botón de generar reporte
    this.thingTypeFilter.selectAction = this.thingTypeFilterCallback.bind(this);

    this.byWorkFrontFilter.selectAction = this.reportByWorkFrontOnChange.bind(this);

    this.workFrontFilter.selectAction = this.validateSelector.bind(this);

    this.stateFilter.selectAction = this.validateSelector.bind(this);


    const { data: activities } = await activityApi.getAllForSelector();
    activities.forEach(activity => {
      activity.name = this.$t(`mapReport.ACTIVITIES.${activity.key}`);
    });

    this.activityFilter.data.cleanAndUpdate(activities);
    this.getStateTypes();
    this.setConfiguration();
  },
  computed: {
    /**
     * Detalles del gráfico (html)
     */
    details() {
      const activity = this.activityFilter.selectedData && this.activityFilter.selectedData.length ? this.activityFilter.selectedData[0].name : '';
      const thingType = this.thingTypeFilter.selectedData && this.thingTypeFilter.selectedData.length ? this.thingTypeFilter.selectedData[0].name : '';
      const workFronts = this.workFrontFilter.selectedData && this.workFrontFilter.selectedData.length ? this.workFrontFilter.selectedData.map(workFront => workFront.name) : '';
      const states = this.stateFilter.selectedData && this.stateFilter.selectedData.length ? this.stateFilter.selectedData.map(state => state.name) : '';
      const timeNames = getTimeRangeNames();
      const byWorkFront = this.byWorkFrontFilter.selectedData && this.byWorkFrontFilter.selectedData.length ? this.byWorkFrontFilter.selectedData[0].name : '';

      let details = `<h3>${this.$t('chart.chart')}: ${this.$t('objectiveTendency.objectivesPerStates.title')}</h3>`
      details += `<h5>${this.$t('objectiveTendency.tendencyReport.details.reportBy')}: ${byWorkFront}</h5>`
      details += `<h5>${this.$t('activity')}: ${activity}</h5>`
      details += `<h5>${this.$t('type')}: ${thingType}</h5>`
      details += `<h5>${this.$t('headers.workFront')}: ${workFronts}</h5>`
      details += `<h5>${this.$t('headers.state')}: ${states}</h5>`
      details += `<h5>${this.$t('selector.dateAndTime.label')}: ${timeNames[this.dashboardModel.selectedDateAndTimeRange]}</h5>`;
      return details;
    }
  },
  methods: {
    async setConfiguration() {
      let config = await configurationService.get(this.chartComponentId);
      this.chartConfiguration = config;
      if (config && config.data) {
        config = config.data;
        this.activityFilter.selectedData = config.activities;
        this.thingTypeFilter.selectedData = config.types;
        this.byWorkFrontFilter.selectedData = config.reportType;
        this.workFrontFilter.selectedData = config.things;
        this.stateFilter.selectedData = config.state;
        if (this.activityFilter.selectedData && this.activityFilter.selectedData.length) {
          const activityId = this.activityFilter.selectedData[0].id;
          const thingTypesResponse = await thingApi.getThingTypesByActivity([activityId], objectiveTendencyConstants.SECTOR_KEY.NO_SECTOR);
          this.thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data);
          this.thingTypeFilter.disabled = false;
        }
        if (this.thingTypeFilter.selectedData && this.thingTypeFilter.selectedData.length) {
          await this.setThingTypeFilter();
        }
        if (config.selectedDateAndTimeRange && this.dashboardModel.dateAndTimeRanges.find(range => range.id === config.selectedDateAndTimeRange)) {
          this.dashboardModel.selectedDateAndTimeRange = config.selectedDateAndTimeRange;
          // custom
          if ((config.selectedDateAndTimeRange === TimeRanges.CUSTOM) && config.selectedDateAndTimeRangeCustomType) {
            this.dashboardModel.selectedDateAndTimeRangeCustomType = config.selectedDateAndTimeRangeCustomType;
            switch (config.selectedDateAndTimeRangeCustomType) {
              case CustomTimeRangeTypes.DATE:
                if (config.sinceDate && config.toDate) {
                  this.dashboardModel.sinceDate = config.sinceDate;
                  this.dashboardModel.toDate = config.toDate;
                }
                break;
              case CustomTimeRangeTypes.DATE_AND_TIME:
                if (config.sinceDate && config.toDate && config.sinceTime && config.toTime) {
                  this.dashboardModel.sinceDate = config.sinceDate;
                  this.dashboardModel.sinceTime = config.sinceTime;
                  this.dashboardModel.toDate = config.toDate;
                  this.dashboardModel.toTime = config.toTime;
                }
                break;
              case 'DAY':
                if (config.sinceDate) {
                  this.dashboardModel.sinceDate = config.sinceDate;
                }
                break;
            }
          }
        }
        if (config.activities.length &&
          config.types.length &&
          config.reportType.length &&
          config.things.length &&
          config.state.length) {
          this.generateReport(this.dashboardModel);
        }
      }
    },
    async validateSelector() {
      await new Promise(resolve => setTimeout(resolve, 10));
      const customTimeValidation = this.dashboardModel.selectedDateAndTimeRange !== TimeRanges.CUSTOM
        || !!(this.dashboardModel.sinceDate
          && this.dashboardModel.toDate
          && this.dashboardModel.customDateTimeValidForm)
        || (this.dashboardModel.selectedDateAndTimeRangeCustomType === 'DAY' && this.dashboardModel.sinceDate);
      const hasActivity = this.activityFilter.selectedData && this.activityFilter.selectedData.length;
      const hasType = this.thingTypeFilter.selectedData && this.thingTypeFilter.selectedData.length;
      const hasReportType = this.byWorkFrontFilter.selectedData && this.byWorkFrontFilter.selectedData.length;
      const hasState = this.stateFilter.selectedData && this.stateFilter.selectedData.length;
      const hasWorkFront = this.workFrontFilter.selectedData && this.workFrontFilter.selectedData.length;
      this.dashboardModel.disableSaveButton = !(customTimeValidation && hasActivity && hasType && hasReportType && hasState && hasWorkFront);
    },
    dateSelectorChanged() {
      this.validateSelector();
    },
    async setThingTypeFilter() {
      const thingTypeId = this.thingTypeFilter.selectedData[0].id;
      const activityId = this.activityFilter.selectedData[0].id;
      const thingTypesResponse = await thingApi.getWorkFrontsByTypes([thingTypeId], null, activityId);
      this.generateColorScheme(thingTypesResponse.data);
      this.workFrontFilter.disabled = !(this.byWorkFrontFilter.selectedData && this.byWorkFrontFilter.selectedData.length);
      this.workFrontFilter.data.cleanAndUpdate(thingTypesResponse.data);
      if (this.byWorkFrontFilter.selectedData[0].id === "WORK_FRONT") {
        this.workFrontFilter.dataTemp = copyJson(this.workFrontFilter.data);
        this.workFrontFilter.data.cleanAndUpdate(objectiveTendencyService.getWorkFrontToSelect(this.workFrontFilter.data));
      }
      this.validateSelector();
    },
    generateColorScheme(workFronts = []) {
      workFronts.forEach(workFront => {
        if (workFront.color) {
          this.colorScheme[workFront.id] = workFront.color;
        }
      });
    },
    async getStateTypes() {
      const statesTypesResponse = await stateService.getStatesTypesByEnterpriseId();
      let lang = localStorage.getItem('locale') || i18n.locale.split('-')[0]
      const statesTypes = statesTypesResponse.map(item => {
        return {
          id: item._id,
          name: item.name[lang],
          key: item.key,
        };
      })
      this.stateFilter.data.cleanAndUpdate(statesTypes);
    },
    reportByWorkFrontOnChange() {
      if (this.byWorkFrontFilter.selectedData && this.byWorkFrontFilter.selectedData.length) {
        this.workFrontFilter.selectedData = [];
        if (this.workFrontFilter.data && this.workFrontFilter.data.length) {
          if (this.byWorkFrontFilter.selectedData[0].id === "WORK_FRONT") {
            this.workFrontFilter.dataTemp = copyJson(this.workFrontFilter.data);
            this.workFrontFilter.data.cleanAndUpdate(objectiveTendencyService.getWorkFrontToSelect(this.workFrontFilter.data));
          } else {
            this.workFrontFilter.data.cleanAndUpdate(this.workFrontFilter.dataTemp);
          }
          this.workFrontFilter.disabled = false;
        }
      } else {
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
      }
      this.validateSelector();
    },
    async activityFilterCallback() {
      if (this.activityFilter.selectedData && !this.activityFilter.selectedData.length) {
        this.thingTypeFilter.selectedData = [];
        this.thingTypeFilter.disabled = true;
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
      } else {
        this.thingTypeFilter.selectedData = [];
        this.thingTypeFilter.disabled = false;
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
        const activityId = this.activityFilter.selectedData[0].id;
        const thingTypesResponse = await thingApi.getThingTypesByActivity([activityId], objectiveTendencyConstants.SECTOR_KEY.NO_SECTOR);
        this.thingTypeFilter.data.cleanAndUpdate(thingTypesResponse.data);
        this.validateSelector();
      }
    },
    async thingTypeFilterCallback() {
      if (this.thingTypeFilter.selectedData && !this.thingTypeFilter.selectedData.length) {
        this.workFrontFilter.selectedData = [];
        this.workFrontFilter.disabled = true;
      } else {
        this.workFrontFilter.selectedData = [];
        await this.setThingTypeFilter();
        this.validateSelector();
      }
    },
    async generateReport(filters) {
      if (this.intervalReport) {
        clearInterval(this.intervalReport);
      }
      this.intervalReport = setInterval(async () => {
          await this.getReport(filters);
        }, 60000 * 5);
      await this.saveConfig(filters);
      await this.getReport(filters);
    },
    async getReport(data) {
      this.timeRangeType = data.selectedDateAndTimeRangeCustomType;
      this.loading = true;
      try {
        this.dashboardModel.charts.clean();
        data.percentageResult = this.dashboardModel.percentageSwitchModel;
        data.colorScheme = this.colorScheme;
        const result = await objectiveTendencyService.getTendencyStatus(data);
        this.dashboardModel.charts.cleanAndUpdate(result);
        this.chartRenderKey = (Math.random() + 1).toString(36).substring(7);
      } finally {
        this.loading = false;
      }
    },
    async saveConfig(filters) {
      const [reportType, activityFilter, thingTypeFilter, thingsFilter, stateFilter] = filters.filters;
      await configurationService.save(this.chartComponentId,
        {
          activities: activityFilter.selectedData,
          types: thingTypeFilter.selectedData,
          reportType: reportType.selectedData,
          things: thingsFilter.selectedData,
          state: stateFilter.selectedData,
          selectedDateAndTimeRange: filters.selectedDateAndTimeRange,
          selectedDateAndTimeRangeCustomType: filters.selectedDateAndTimeRangeCustomType,
          sinceDate: filters.sinceDate,
          toDate: filters.toDate,
        });
    },
  },
  beforeDestroy() {
    if (this.intervalReport) {
      clearInterval(this.intervalReport);
    }
  }
}
